<template>
  <div class="confirm-order">
    <div class="confirm-content">
      <div class="confirm-title">收银台</div>
      <div class="left">
        <div class="info-line">
          <div class="info-box">
            <span class="info-title">订单编号：</span>
            <span class="info-text oreder-num">{{ payInfo.order_no }}</span>
          </div>
          <div class="info-box">
            <span class="info-title">应付金额：</span>
            <span class="info-text money">￥{{ payInfo.price }}</span>
          </div>
          <!-- <span class="check-order-info">查看订单信息</span> -->
        </div>
        <div class="info-line">
          <div class="info-box">
            <span class="info-title">请选择支付类型：</span>
            <span class="info-text">
              <label @click="payType = 0">
                <div class="pay-type weixin" :class="{ active: payType == 0 }">
                  微信
                </div>
              </label>
              <label @click="payType = 1">
                <div
                  class="pay-type zhifubao"
                  :class="{ active: payType == 1 }"
                >
                  支付宝
                </div>
              </label>
            </span>
          </div>
        </div>
        <button class="pay-btn" @click="payBtn">立即支付</button>
        <div class="tip">课程属于虚拟商品，不支持退款</div>
      </div>
    </div>
    <div class="code-show-box" v-if="wexinPayLink != ''">
      <div class="code-show">
        <div class="close" @click="closeBtn"></div>
        <div class="code-title">打开手机微信扫描二维码</div>
        <div class="img_mask">
          <img class="code-img" :src="wexinPayLink" />
          <div v-if="maskState">
            <div class="mask">
              <div class="loading4">
                <div class="three1"></div>
                <div class="three2"></div>
                <div class="three3"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="code-refresh" @click="payBtn('again')">刷新二维码</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "Cashier",
  data() {
    return {
      payInfo: "",
      payType: 0,
      wexinPayLink: "",
      timer: "",
      maskState: false,
    };
  },
  mounted() {
    this.payInfo = JSON.parse(localStorage.getItem("orderInfo"));
  },
  methods: {
    ...mapMutations(["ShowTipModal"]), //tip弹窗
    payBtn(tips) {
      if (tips === "again") {
        this.maskState = true;
        setTimeout(() => {
          this.maskState = false;
        }, 1000);
      }

      let www = window.location.href.split("/#")[0]; //获取域名
      let return_url = "";

      if (this.payInfo.courseId.includes("series")) {
        return_url =
          www + "/#/seriesLessonsInfo/" + this.payInfo.courseId.split("_")[1];
      } else {
        return_url = www + "/#/recordedInfo/" + this.payInfo.courseId;
      }

      let data = {
        order_no: this.payInfo.order_no,
        pay_type: this.payType,
        return_url,
      };

      this.$axios
        .post(`/v1/course/payOrder`, data, { useLog: true })
        .then((res) => {
          if (res.code == 1) {
            this.ShowTipModal({
              text: res.msg, //提示弹窗内容
            });
          } else {
            if (this.payType == 0) {
              this.getOrderStatus(www);
              this.wexinPayLink = res.data.content.link;
            } else {
              let routeUrl = this.$router.resolve({
                path: "/aliPay",
                query: { htmlData: res.data.content },
              });

              window.open(routeUrl.href, "_blank");

              //this.$router.push({path:'/aliPay',query:{ htmlData: res.data.content}})
            }
          }
        });
    },
    getOrderStatus(www) {
      let _this = this;
      let return_url = "";
      if (this.timer) {
        clearInterval(_this.timer);
      }
      this.timer = setInterval(function () {
        _this.$axios
          .post(
            `/v1/course/orderStatus`,
            {
              order_no: _this.payInfo.order_no,
            },
            { useLog: true }
          )
          .then((res) => {
            if (res.code == 0) {
              clearInterval(_this.timer);

              if (_this.payInfo.courseId.includes("series")) {
                return_url =
                  www +
                  "/#/seriesLessonsInfo/" +
                  _this.payInfo.courseId.split("_")[1];
              } else {
                return_url = www + "/#/recordedInfo/" + _this.payInfo.courseId;
              }

              window.location.href = return_url;
            }
          });
      }, 3000);
    },
    //关闭二维码
    closeBtn() {
      this.wexinPayLink = "";
      clearInterval(this.timer);
    },
  },
  beforeDestroy() {
    if (localStorage.getItem("orderInfo")) {
      localStorage.removeItem("orderInfo");
    }
  },
};
</script>
<style scoped lang="scss">
.confirm-order {
  background-color: #f7f7f7;
  padding: 64px 0 72px;
}
.confirm-content {
  box-sizing: border-box;
  width: 1200px;
  padding: 34px;
  background-color: #fff;
  margin: 0 auto;
  overflow: hidden;
}
.confirm-title {
  font-size: 24px;
  font-weight: 800;
  color: #333;
  margin-bottom: 30px;
}
.left {
  width: 680px;
  float: left;
}
.info-line {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.info-box {
  float: left;
  display: flex;
  align-items: center;
  .info-title,
  .info-text {
    font-size: 14px;
    color: #666;
    white-space: nowrap;
    float: left;
    display: flex;
    align-items: center;
    label {
      margin: 0 30px 0 34px;
      cursor: pointer;
    }
    .pay-type {
      height: 24px;
      line-height: 24px;
      overflow: hidden;
      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        background: url(~@/assets/yuan-grey-ico.png) no-repeat;
        background-size: 100%;
        float: left;
        margin-top: 4px;
      }
      &.active::before {
        background: url(~@/assets/yuan-blue-ico.png) no-repeat;
        background-size: 100%;
      }
      &::after {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background: url(~@/assets/weixin-logo.png) no-repeat;
        background-size: 100%;
        float: left;
        margin: 0 10px 0 20px;
      }
      &.zhifubao::after {
        background: url(~@/assets/zhifubao-logo.png) no-repeat;
        background-size: 100%;
      }
    }
    &.oreder-num {
      width: 260px;
    }
  }
  .info-text {
    color: #000;
    &.money {
      font-size: 18px;
      font-weight: 900;
      color: #cd1b14;
      width: 200px;
    }
  }
}
.check-order-info {
  font-size: 14px;
  color: #254ed4;
  cursor: pointer;
  white-space: nowrap;
}
.pay-btn {
  width: 153px;
  height: 40px;
  line-height: 40px;
  background: #254ed4;
  border-radius: 6px;
  font-size: 16px;
  color: #fff;
  margin-bottom: 12px;
  cursor: pointer;
}
.tip {
  font-size: 12px;
  color: #999;
}
.code-show-box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;
}
.code-show {
  width: 398px;
  height: 412px;
  background: #fff;
  box-shadow: 0px 0px 14px 0px rgba(0, 12, 40, 0.14);
  border-radius: 8px;
  position: relative;
  .close {
    width: 20px;
    height: 20px;
    background: url(~@/assets/close-ico.png) no-repeat center center;
    position: absolute;
    top: 4px;
    right: -50px;
    cursor: pointer;
  }
  .code-title {
    font-size: 18px;
    color: #333;
    text-align: center;
    padding-top: 51px;
    margin-bottom: 34px;
  }
  .img_mask {
    position: relative;
    width: 188px;
    height: 188px;
    margin: 0 auto 24px;
    .mask {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
      text-align: center;
      line-height: 188px;
      color: #000;
      font-size: 20px;
      .loading4 {
        width: 150px;
        margin: auto;
        text-align: center;
      }
      .loading4 > div {
        width: 18px;
        height: 18px;
        border-radius: 100%;
        display: inline-block;
        background-color: #666;
        -webkit-animation: three 1.4s infinite ease-in-out;
        animation: three 1.4s infinite ease-in-out;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
      }
      .loading4 .three1 {
        -webkit-animation-delay: -0.3s;
        animation-delay: -0.3s;
      }
      .loading4 .three2 {
        -webkit-animation-delay: -0.15s;
        animation-delay: -0.15s;
      }
      @-webkit-keyframes three {
        0%,
        80%,
        100% {
          -webkit-transform: scale(0);
        }
        40% {
          -webkit-transform: scale(1);
        }
      }
      @keyframes three {
        0%,
        80%,
        100% {
          -webkit-transform: scale(0);
        }
        40% {
          -webkit-transform: scale(1);
        }
      }
    }
    .code-img {
      display: block;
      width: 188px;
      height: 188px;
      background: #fff;
      box-shadow: 0px 0px 4px 0px rgba(0, 12, 40, 0.14);
      border-radius: 6px;
      margin: 0 auto 24px;
    }
  }

  .code-refresh {
    font-size: 14px;
    color: #666;
    text-align: center;
    cursor: pointer;
  }
}
</style>